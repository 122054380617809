<template>
  <div class="map-container">
    <div class="heard">
      <div class="heartitle">
        <img
          class="companylogin"
          src="../../assets/列表页切图/2-logo@2x.png"
          alt=""
          @click="$router.push('/')"
        />
        <img
          class="positioning"
          @click="$router.push('/map')"
          src="../../assets/列表页切图/dingwei.png"
          alt=""
        />
        <img
          class="map"
          @click="$router.push('/map')"
          src="../../assets/列表页切图/lishiditu.png"
          alt=""
        />

        <!-- <div class="titleright">
          <el-input
            class="input"
            placeholder="搜索"
            prefix-icon="el-icon-search"
            v-model="value"
            color="#F3AA19;"
          >
          </el-input> -->
        <!-- <div class="mylogin">
            <img class="myimg" src="../../assets/首页切图/my.png" alt="" /> -->
        <!-- <span class="logintext">请登录</span> -->
        <!-- </div> -->
        <!-- </div> -->
      </div>
      <div class="content">
        <div class="content-box">
          <el-image class="mapbox" :src="urlMap" lazy alt="" />
          <el-popover
            placement="bottom"
            width="300"
            trigger="hover"
            content="中国的原始社会，约从一百七十万年前的元谋人开始，到公元前2070年夏朝建立前夕为止，经历了原始人群和氏族公社两个阶段。氏族公社包括母系氏族公社和父系氏族公社。
云南元谋人、陕西蓝田人（距今约八十万年）和北京人（距今约70万年~20万年）都处于原始人群阶段。北京人已经能够制造和使用简单的劳动工具，使用天然火，通过采集和狩猎获取食物，原始人群是早期的人类社会。
到一万七千年前，山顶洞人按血统关系组成比较固定的集团，依靠集体力量向大自然作斗争，开始过着氏族公社的生活。他们采取磨制和钻孔技术制造石器、骨器，并学会制造骨针，把兽皮缝成衣服，还懂得了人工取火，劳动技能比前人有了更大的进步."
          >
            <div slot="reference" class="one">
              <h5>1</h5>
            </div>
          </el-popover>
          <el-popover
            placement="bottom"
            width="300"
            trigger="hover"
            content="夏朝建立之前的历史时期，统称为“上古时期”，指文字记载出现以前的历史时代。或称为“上古时代”、“远古时代”、“三皇五帝时代”。对世界各地上古时代的定义也因此不同。在中国上古时代一般指夏以前的时代。在两河流域和埃及一般指公元前5000年以前的历史时代。因为上古时代没有当时直接的文字记载，那个时候发生的事件或人物一般无法直接考证。这些事件和人物也往往带有神话色彩。据“中华文明探源工程”研究成果表明在距今五千年前中华大地已出现了国家形式，与传说中所描述的天下万国、天下万邦的情景相吻合，该时期称为上古时代。中华文明的起源和早期发展，是一段没有被文字直接记录下来的历史。“中华文明探源工程”的另一重要成果是丰富了对人类文明起源的认知。中华上古时代人物有：有巢氏、燧人氏、伏羲氏、神农氏、轩辕氏等。"
          >
            <div slot="reference" class="two">
              <h5>2</h5>
            </div>
          </el-popover>
          <el-popover
            placement="bottom"
            text-indent="2em"
            width="300"
            trigger="hover"
            content="   夏朝是中国历史上从“公天下”到“家天下”的转变，是从夏朝的建立开始的，史书上记载的第一个世袭制朝代。 一般认为夏朝共传14代，17后 (夏统治者在位称 '后'，去世后称'帝')，延续约471年 ，为商朝所灭。后人常以“华夏”自称，使之成为中国的代名词 。
夏桀在位期间与周边部落关系恶化和破裂，不顾民众疾苦，荒淫无度，使百姓哀声哉道，最终商部落首领成汤率领周边各部落军队讨伐夏桀，夏朝覆灭。"
          >
            <div slot="reference" class="three">
              <h5>3</h5>
            </div>
          </el-popover>
          <el-popover
            placement="bottom"
            width="300"
            trigger="hover"
            content="商朝是中国历史上的第二个朝代，是中国第一个有直接的同时期的文字记载的王朝。商朝经历了三个大的阶段。第一阶段是'先商';第二阶段是'早商';第三阶段是'晚商'，前后相传17世31王，延续500余年。
商朝末年的君主逐渐变得昏庸，最有代表的是纣王，纣王的荒淫残暴丧失了民心，而此时另一个部落且逐渐强盛起来，它就是周部落，周部落的首领周武王一直想除掉纣王，但又没有好的计策和时机，当商朝主力军队正在远征东夷的时候，国都朝歌成了空城，周部落的周武王帅诸侯攻打朝歌，商朝人把牢房里的奴隶和战俘武装起来迎战，双方在朝歌附近的牧野展开厮杀，史称“牧野之战”。周人不费吹灰之力就取得了胜利，兵临朝歌城下，纣王见大势已去，登上鹿台，自焚而死，商朝灭亡。"
          >
            <div slot="reference" class="four">
              <h5>4</h5>
            </div>
          </el-popover>
          <el-popover
            placement="bottom"
            width="300"
            trigger="hover"
            content="周朝是中国历史上继商朝之后的第三个华夏族奴隶制王朝。周王朝一共传国君32代37王，享国共计790年。
周朝分为西周和东周两个时期。 周朝由周武王姬发创建，定都镐京，营建东都成周洛邑，何尊铭文记述了周成王营建(或曰迁都)成周、举行祭祀、赏赐臣子的一系列活动。 其后周穆王又筑宫南郑， 其后周懿王又迁都犬丘(今陕西咸阳)。公元前771年镐京陷落，西周灭亡;公元前770年(周平王元年)，平王东迁，定都成周(今河南洛阳)，此后周朝的这段时期称为东周。史书又将西周和东周合称为两周。其中东周以'三家分晋'为节点，又分为春秋和战国两个时期。
春秋时期是中国历史阶段之一，即东周前半期历史阶段，平王动迁后，周王室势力减弱，诸侯群雄纷争，齐桓公、晋文公、宋襄公、秦穆公、楚庄王相继称霸，史称春秋五霸。公元前453年，韩、赵、魏推翻智氏，奠定了战国七雄的格局，形成了以秦、魏、韩、赵、楚、燕、齐等七国的大分裂格局，战国七雄各自为政、连年征战，导致整个国家十分动荡"
          >
            <div slot="reference" class="five">
              <h5>5</h5>
            </div>
          </el-popover>
          <el-popover
            placement="bottom"
            width="300"
            trigger="hover"
            content="秦王灭六国完成天下统一，建立秦朝，定都咸阳，不仅统一了四分五裂的中华大地，而且在政治、经济上进行一系列改革，统一货币、文字、度量衡，为抵制匈奴侵扰，秦始皇下令修建了万里长城。公元前210年，始皇帝出游到达平远渡沙丘宫平台时突然暴毙，传遗诏给公子扶苏，让他回咸阳主持大局，遗诏落到大臣赵高手里，赵高一心扶持胡亥，他伙同李斯伪造诏书赐死长公子扶苏，扶持胡亥即位，开启了秦朝灭亡的大门。公元前206年，刘邦攻入咸阳，派人劝子婴投降，秦朝群臣见大势已去纷纷投降，子婴手捧玉溪和兵符向刘邦投降，秦朝彻底灭亡。"
          >
            <div slot="reference" class="six">
              <h5>6</h5>
            </div>
          </el-popover>
          <el-popover
            placement="bottom"
            width="300"
            trigger="hover"
            content="汉朝是继秦朝之后的大一统王朝，分为西汉、东汉时期，共历29帝，享国四百零七年。秦末农民起义，刘邦推翻秦后被封为汉王。楚汉争霸，刘邦战胜项羽并称帝建立汉朝，定都长安，史称西汉。汉文帝、汉景帝推行休养生息国策，开创'文景之治';汉武帝即位后推行推恩令、'罢黜百家，独尊儒术'、加强中央集权，派张骞出使西域沟通中原与西域各国的联系开辟丝绸之路，北击匈奴，东并朝鲜，攘夷扩土成就'汉武盛世';至汉宣帝时期国力达到极盛，设立西域都护府，将西域纳入版图，开创'孝宣之治'。公元8年王莽废汉儒子，定都长安，史称新莽，西汉灭亡。
公元25年刘秀重建汉朝，定都洛阳，史称东汉。刘秀统一天下后息兵养民，史称'光武中兴';汉明帝、汉章帝沿袭轻徭薄赋，开创'明章之治'，东汉国力达到鼎盛;汉和帝继位后励精图治，军事上迫使匈奴西迁、班超将丝绸之路延伸至欧洲，在西域经营三十多年使中原和西域的文化进一步融合，公元100年罗马帝国遣使来朝，这是东西方首次直接交往。东汉国力趋于极盛，史称'永元之隆' 公元220年曹丕篡汉，汉朝灭亡。
华夏族自汉朝以后逐渐被称为汉族，汉朝在科技领域也颇有成就，如蔡伦改进了造纸术成为中国四大发明之一，张衡发明了地动仪、浑天仪等。"
          >
            <div slot="reference" class="seven">
              <h5>7</h5>
            </div>
          </el-popover>
          <el-popover
            placement="bottom"
            width="300"
            trigger="hover"
            content="三国是中国汉朝与晋朝之间的一段历史时期，分为曹魏、蜀汉、东吴三个政权。220年，汉朝丞相曹丕篡汉称帝，定都洛阳，国号'魏'，史称曹魏，汉朝正式结束。221年刘备称帝，定都成都，史称蜀汉。229年孙权称帝，定都建邺，国号'吴'，史称东吴。
赤壁之战时，曹操被孙刘联军击败，奠定了三国鼎立的雏型。此后的数十年内，蜀汉诸葛亮、姜维多次率军北伐曹魏，但始终未能改变三足鼎立的格局。曹魏后期的实权渐被司马懿掌控。263年，曹魏的司马昭发动魏灭蜀之战，蜀汉灭亡。
265年，魏国相国司马炎废魏元帝自立，建国号为'晋'，史称西晋。三国时代正式结束，中国历史正式进入了晋朝时代。280年，晋灭东吴，统一中国，三国时期彻底结束。"
          >
            <div slot="reference" class="eight">
              <h5>8</h5>
            </div>
          </el-popover>
          <el-popover
            placement="bottom"
            width="300"
            trigger="hover"
            content="晋朝分为西晋与东晋两个时期。其中西晋为中国历史上大一统王朝，东晋则属于六朝之一，两晋共传十五帝，共一百五十五年。
266年司马炎篡魏，建立政权，国号为晋，定都洛阳，史称西晋，司马炎即晋武帝。280年，西晋灭吴，完成统一。后经历八王之乱和永嘉之祸，国势渐衰。313年，晋愍帝迁都长安，316年，西晋在胡马的嘶鸣中被匈奴人灭亡。317年，西晋皇室南渡江南，司马睿在建邺（今江苏省南京市）延续晋朝，史称东晋。东晋曾多次北伐中原汉地。383年东晋与前秦淝水之战后，东晋以少胜多，得到暂时巩固。两晋时期北方南迁的汉人将大量生产力与先进技术带入江南，进一步开发了江南地区。420年，刘裕建立刘宋，东晋灭亡。"
          >
            <div slot="reference" class="nine">
              <h5>9</h5>
            </div>
          </el-popover>
          <el-popover
            placement="bottom"
            width="300"
            trigger="hover"
            content="南北朝是南朝和北朝的统称，南北朝是分裂的乱世，也是中国历史上一段大融合的时期，更是一个充满悲情和风情的时代。南朝是温婉的江南水乡，出身士族的南人在这里叱咤风云，北朝是苍茫的塞上草原，胡服骑射的北人在此争夺天下。自东晋十六国至隋朝，始于420年刘裕建立刘宋，589年隋文帝派晋王杨广率众将士讨伐陈叔宝，统一中国，结束了自西晋末年以来中国长达近300年的分裂局面。"
          >
            <div slot="reference" class="ten">
              <h5>10</h5>
            </div>
          </el-popover>
          <el-popover
            placement="bottom"
            width="300"
            trigger="hover"
            content="隋朝是一个充满传奇色彩的朝代，在中国历史上承南北朝，下启唐朝的大一统朝代，享国三十八年。它结束了南北分裂的局面，成为开启大唐盛世的一把金钥匙，它又是一个充满矛盾的朝代，它富强文明，又暴虐短命，隋文帝是一代明君，被西方人尊称为“圣人可汗”，隋炀帝却成了暴虐昏君的代名词。它为唐朝盛世创造了条件，隋文帝统治的二十多年（公元581年-公元604年）大力发展生产，开创了“开皇之治”的繁荣局面。隋炀帝登基后，将国都迁到洛阳，修建了贯通南北的大运河，但因过度消耗国力，社稷动摇，引发了隋末民变和贵族叛变，618年宇文化及在江都勒死了隋炀帝，立秦王杨浩为傀儡皇帝。李渊逼杨侑禅让，将国号定位'唐'，隋朝彻底灭亡。"
          >
            <div slot="reference" class="eleven">
              <h5>11</h5>
            </div>
          </el-popover>
          <el-popover
            placement="bottom"
            width="300"
            trigger="hover"
            content="唐朝是继隋朝之后的大一统中原王朝，共历二十一帝，享国二百八十九年。
玄武门之变后，李渊将皇位传给了理事们，李世民成为“唐太宗”，唐太宗继位后开创贞观之治，为盛唐奠定基础。唐高宗承贞观遗风开创'永徽之治'，并于657年建东都洛阳 。690年，武则天改国号为周，705年神龙革命后，恢复唐国号。 唐玄宗即位后缔造全盛的开元盛世，天宝末全国人口达八千万左右。 安史之乱后藩镇割据、宦官专权导致国力渐衰；中后期经唐宪宗元和中兴、唐武宗会昌中兴、唐宣宗大中之治国势复振。878年爆发黄巢起义破坏了唐朝统治根基，907年朱温篡唐，夺取了唐哀帝的帝位，唐朝覆亡。
唐朝接纳各国交流学习，经济、社会、文化、艺术呈现出多元化、开放性等特点 ，诗、书、画、乐等方面涌现出大量名家，如诗仙李白、诗圣杜甫、诗魔白居易，书法家颜真卿，画圣吴道子，音乐家李龟年等。"
          >
            <div slot="reference" class="twelve">
              <h5>12</h5>
            </div>
          </el-popover>
          <el-popover
            placement="bottom"
            width="300"
            trigger="hover"
            content="五代十国是中国历史上的一段大分裂时期。是五代(907年-960年 )与十国(902年-979年 )的合称。五代是指907年唐朝灭亡后依次定都于中原地区的五个政权，即后梁、后唐、后晋、后汉和后周。公元907年，唐朝灭亡后，朱温在中原地区建立后梁，定都东京开封府(今河南开封)，五代十国开始。 “十国”的国土面积各不相同，成立时间也不一样，但是它们比五代中的每一代都要长久，960年，后周赵匡胤发动陈桥兵变，黄袍加身，篡后周建立北宋，一扫南北，结束了十个割据政权。"
          >
            <div slot="reference" class="thirteen">
              <h5>13</h5>
            </div>
          </el-popover>
          <el-popover
            placement="bottom"
            width="300"
            trigger="hover"
            content="宋朝分北宋和南宋两个阶段，共历十八帝，享国三百一十九年。
960年，后周诸将发动陈桥兵变，拥立宋州归德军节度使赵匡胤为帝，建立宋朝。 赵匡胤为避免晚唐藩镇割据和宦官专权乱象，采取重文抑武方针，加强中央集权，并剥夺武将兵权。宋太宗继位后统一全国，宋真宗与辽国缔结澶渊之盟后逐渐步入治世。1125年金国大举南侵，导致靖康之耻，北宋灭亡。康王赵构于南京应天府即位，建立了南宋。绍兴和议后与金国以秦岭-淮河为界，1234年联蒙灭金，1235年爆发宋元战争，1276年元朝攻占临安，崖山海战后，南宋灭亡。
宋朝时期，儒学复兴，出现程朱理学，科技发展迅速，政治开明，中国四大发明中有三项是在北宋发展成熟并普及，火药、活字印刷、指南针，文学方面，宋朝的词作品已达到极高的水平，与唐诗并称为中国古典文学艺术的瑰宝，书法成就更是斐然，苏轼、黄庭坚、米芾、蔡襄成就最高，被称为“北宋四大家”。立国三百余年，二度倾覆，皆缘外患，是唯独没有直接亡于内乱的王朝。"
          >
            <div slot="reference" class="fourteen">
              <h5>14</h5>
            </div>
          </el-popover>
          <el-popover
            placement="bottom"
            width="300"
            trigger="hover"
            content="元朝是中国历史上首次由少数民族建立的大一统王朝，统治者为蒙古孛儿只斤氏。定都大都(今北京)，传五世十一帝，从1206年成吉思汗建立蒙古政权始为162年，从忽必烈定国号元开始历时98年。
1206年，成吉思汗统一蒙古各部，建立大蒙古国。先后攻灭西辽、西夏、花剌子模、金朝等政权。蒙哥汗去世后，引发了阿里不哥与忽必烈的汗位之争，促使大蒙古国分裂。1260年忽必烈即位称帝，建元'中统'。1271年，忽必烈在大都（今北京）称帝，建立元朝，实行行省制。1279年，彻底灭亡南宋流亡政权，元朝统一中国，建立我国历史上第七个封建大一统皇朝。后期政治腐败，权臣干政，民族矛盾与阶级矛盾日益加剧，导致元末农民起义。 1368年，朱元璋建立明朝，随后北伐驱逐元廷攻占大都。此后元廷退居漠北，史称北元。1402年，元臣鬼力赤篡夺政权建立鞑靼，北元灭亡。"
          >
            <div slot="reference" class="fifteen">
              <h5>15</h5>
            </div>
          </el-popover>
          <el-popover
            placement="bottom"
            width="300"
            trigger="hover"
            content="明朝是中国历史上最后一个由汉族建立的大一统中原王朝，共传十二世，历经十六帝，享国二百七十六年。
元末爆发红巾起义，朱元璋加入郭子兴队伍。1364年朱元璋称吴王，建立西吴。1368年朱元璋称帝，国号为大明，因皇室姓朱，又称朱明，定都于应天府；1420年朱棣迁都至顺天府，以应天府为陪都。明初历经洪武之治、永乐盛世、仁宣之治等治世，政治清明、国力强盛。中期经土木之变由盛转衰，后经弘治中兴、嘉靖中兴、万历中兴国势复振，晚明因东林党争和天灾外患导致国力衰退，爆发明末农民起义。1644年李自成攻入北京，北京城中的军民除了逃窜便是投降，崇祯皇帝最终在煤山（今景山公园）的一颗老槐树上自缢身亡，明朝灭亡了。"
          >
            <div slot="reference" class="sixteen">
              <h5>16</h5>
            </div>
          </el-popover>
          <el-popover
            placement="bottom"
            width="300"
            trigger="hover"
            content="清朝是中国历史上最后一个封建王朝，共传十二帝 ，统治者为满洲爱新觉罗氏。从努尔哈赤建立后金起，总计296年。 从皇太极改国号为清起，国祚276年。 从清兵入关，建立全国性政权算起为268年。
1616年，建州女真首领努尔哈赤建立后金。 1636年，皇太极改国号为大清。 1644年，驻守山海关的明将吴三桂降清，多尔衮率领清兵入关。入关后20年时间里，平定大顺、大西、南明等政权。 后又平定三藩之乱、统一台湾，完成全国统一。 康雍乾三朝走向鼎盛，在此期间，中国的传统社会取得了前所未有的发展成就。土地增垦，物产盈丰，小农经济的社会生活繁荣稳定，综合国力远胜于汉唐 。鸦片战争后多遭列强入侵，进行了洋务运动和戊戌变法等近代化的探索和改革。1912年2月12日，北洋大臣袁世凯诱使清帝溥仪逊位，颁布了清帝退位诏书，清朝从此结束。"
          >
            <div slot="reference" class="seventeen">
              <h5>17</h5>
            </div>
          </el-popover>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="center-bom">
        <img
          class="botmlogin"
          src="../../assets/列表页切图/logo@2x.png"
          alt=""
        />
        <div class="bomtext">
          <h6>杭州晨星创文网络科技有限公司</h6>
          <span class="spanone"
            >公司地址：浙江省杭州市钱塘新区佳宝科创中心2幢508室</span
          ><br />
          <span class="spantwo">联系电话：0571-61075056</span>
        </div>
        <img class="opcode" src="../../assets/record.jpg" alt="" />
        <div class="boms">Icp备18038160号-3</div>
      </div>
    </div>
  </div>
</template>

<script>
// import { titles } from "@/api/map";
export default {
  name: "map",
  components: {},
  props: {},
  data() {
    return { visible: false, urlMap: require("../../assets/map@2x.png") };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    // titlelist() {
    //   titles().then((res) => {
    //     console.log(res);
    //   });
    // },
  },
};
</script>

<style scoped lang="less">
.text-box {
  width: 200px;
  font-size: 14px;
  // background-color: pink;
  font-family: "Microsoft YaHei";
  letter-spacing: 4px;
  text-indent: 2em;
  font-weight: 500;
}
.heard {
  width: 100vw;
  height: 100vh;
  background: url("../../assets/空白页切图/2-bgc@2x (2).png") no-repeat;
  padding-top: 0px;
  background-size: 100% 100%;
}

.companylogin {
  width: 160px;
  height: 60px;
  margin-top: 40px;
}
.heartitle {
  position: relative;
  width: 1148px;
  margin: 0 auto;
  height: 160px;
  .positioning {
    position: absolute;
    top: 45%;
    left: 25.5%;
    animation: myfirst 2s infinite;
    width: 20px;
    height: 29px;
  }
  @keyframes myfirst {
    0% {
      transform: translate(0px, 0px);
    }
    50% {
      transform: translate(0px, -12px);
    }
    100% {
      transform: translate(0px, 0px);
    }
  }
  .titleright {
    position: absolute;
    top: 30px;
    right: 17px;
    width: 220px;
    height: 84px;
  }
}
.map {
  margin-left: 7%;
  margin-top: 5%;
  width: 112px;
  height: 79px;
  // width: 156px;
  // height: 102px;
}
/deep/input::-webkit-input-placeholder {
  color: #f3aa19;
  font-weight: 400;
  font-size: 16px;
}
/deep/input::-moz-input-placeholder {
  color: #f3aa19;
  font-size: 16px;
  font-weight: 400;
}
/deep/input::-ms-input-placeholder {
  color: #f3aa19;
  font-size: 16px;
  font-weight: 400;
}
.input {
  width: 146px;
  height: 46px;
  opacity: 0.52;
  /deep/.el-icon-search:before {
    padding-left: 30px;
    color: #f3aa19;
    font-size: 18px;
    line-height: 46px;
  }
  /deep/.el-input__inner {
    border-radius: 20px;
    padding: 20px;
    text-align: center;
    color: #f3aa19;
    line-height: 47px;
  }
}
.mylogin {
  position: absolute;
  top: 0;
  right: 2px;
  width: 48px;
  height: 85px;
  .myimg {
    width: 46px;
    height: 46px;
  }
  .logintext {
    width: 48px;
    height: 16px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    line-height: 36px;
  }
}
.content {
  position: relative;
  width: 100vw;
  height: 100vh;
  padding-top: 40px;
  background: #f2f2f2;
}
.content-box {
  position: relative;
  margin-left: 20%;
  width: 1200px;
  height: 801px;
  margin: 0 auto;
  // width: 100%;
  // height: 100%;
  // background-color: pink;
  // background: url("../../assets/map@2x.png") no-repeat;
  // background-size: 100% 100%;
  .mapbox {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.one {
  position: absolute;
  top: 160px;
  left: 170px;
  background-color: rgb(216, 41, 7);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  h5 {
    text-align: center;
    line-height: 16px;
    color: #fff;
  }
}
.two {
  position: absolute;
  top: 199px;
  left: 436px;
  background-color: rgb(216, 41, 7);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  h5 {
    text-align: center;
    line-height: 16px;
    color: #fff;
  }
}
.three {
  position: absolute;
  top: 128px;
  left: 697px;
  background-color: rgb(216, 41, 7);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  h5 {
    text-align: center;
    line-height: 16px;
    color: #fff;
  }
}
.four {
  position: absolute;
  top: 204px;
  right: 140px;
  background-color: rgb(216, 41, 7);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  h5 {
    text-align: center;
    line-height: 16px;
    color: #fff;
  }
}
.five {
  position: absolute;
  top: 290px;
  right: 195px;
  background-color: rgb(216, 41, 7);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  h5 {
    text-align: center;
    line-height: 16px;
    color: #fff;
  }
}
.six {
  position: absolute;
  top: 280px;
  right: 435px;
  background-color: rgb(216, 41, 7);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  h5 {
    text-align: center;
    line-height: 16px;
    color: #fff;
  }
}
.seven {
  position: absolute;
  top: 324px;
  left: 475px;
  background-color: rgb(216, 41, 7);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  h5 {
    text-align: center;
    line-height: 16px;
    color: #fff;
  }
}
.eight {
  position: absolute;
  top: 304px;
  left: 160px;
  background-color: rgb(216, 41, 7);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  h5 {
    text-align: center;
    line-height: 16px;
    color: #fff;
  }
}
.nine {
  position: absolute;
  bottom: 284px;
  left: 220px;
  background-color: rgb(216, 41, 7);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  h5 {
    text-align: center;
    line-height: 16px;
    color: #fff;
  }
}
.ten {
  position: absolute;
  bottom: 264px;
  left: 430px;
  background-color: rgb(216, 41, 7);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  h5 {
    text-align: center;
    line-height: 16px;
    color: #fff;
  }
}
.eleven {
  position: absolute;
  bottom: 320px;
  left: 690px;
  background-color: rgb(216, 41, 7);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  h5 {
    text-align: center;
    line-height: 16px;
    color: #fff;
  }
}
.twelve {
  position: absolute;
  bottom: 310px;
  right: 150px;
  background-color: rgb(216, 41, 7);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  h5 {
    text-align: center;
    line-height: 36px;
    color: #fff;
  }
}
.twelve {
  position: absolute;
  bottom: 310px;
  right: 150px;
  background-color: rgb(216, 41, 7);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  h5 {
    text-align: center;
    line-height: 16px;
    color: #fff;
  }
}
.thirteen {
  position: absolute;
  bottom: 131px;
  right: 190px;
  background-color: rgb(216, 41, 7);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  h5 {
    text-align: center;
    line-height: 16px;
    color: #fff;
  }
}
.fourteen {
  position: absolute;
  bottom: 108px;
  right: 410px;
  background-color: rgb(216, 41, 7);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  h5 {
    text-align: center;
    line-height: 16px;
    color: #fff;
  }
}
.fifteen {
  position: absolute;
  bottom: 143px;
  left: 638px;
  background-color: rgb(216, 41, 7);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  h5 {
    text-align: center;
    line-height: 16px;
    color: #fff;
  }
}
.sixteen {
  position: absolute;
  bottom: 90px;
  left: 395px;
  background-color: rgb(216, 41, 7);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  h5 {
    text-align: center;
    line-height: 16px;
    color: #fff;
  }
}
.seventeen {
  position: absolute;
  bottom: 50px;
  left: 146px;
  background-color: rgb(216, 41, 7);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  h5 {
    text-align: center;
    line-height: 16px;
    color: #fff;
  }
}

.bottom {
  width: 100vw;
  height: 298px;
  background: #353535;
  .center-bom {
    position: relative;
    width: 1148px;
    height: 100%;
    // background-color: yellow;
    margin: 160px auto;
    .botmlogin {
      position: absolute;
      top: 40px;
      left: 0;
    }
    .bomtext {
      position: absolute;
      top: 70px;
      left: 195px;
      width: 534px;
      height: 110px;
      // background-color: pink;
      h6 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #868686;
      }
      .spanone {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #878787;
      }
      .spantwo {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #878787;
      }
    }
    .opcode {
      position: absolute;
      right: 0;
      top: 50px;
      width: 140px;
      height: 140px;
      background-color: #fff;
    }
    .boms {
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: center;
      width: 100%;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #868686;
    }
  }
}
</style>
